import { render, staticRenderFns } from "./Article81.vue?vue&type=template&id=274c7e64&scoped=true"
import script from "./Article81.vue?vue&type=script&lang=js"
export * from "./Article81.vue?vue&type=script&lang=js"
import style0 from "./Article81.vue?vue&type=style&index=0&id=274c7e64&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "274c7e64",
  null
  
)

/* custom blocks */
import block0 from "@/locales/article81.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Farticle%2FArticle81.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports